import React from "react";
import Text from "../../../CommonComponents/text";
import "../resources.css";
// import images from '../../../helpers/ImportImages'
import { Link } from "react-router-dom";

const Articles = ({ date, website_name, title, img_src, link }) => {
  return (
    <div className="article-card">
      <img src={img_src} alt="" />
      <div className="article-info">
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <div>
            <p>{website_name}</p>
          </div>
          <div>
            <p>{date}</p>
          </div>
        </div>
        <Text text={title?.length > 48 ? title?.slice(0, 41) + "..." : title} color="black" appearance="light-20" />
        <Link to={link} target="_blank">
          <Text
            text="Read More"
            color="secondary-color"
            appearance="light-16"
          />
        </Link>
      </div>
    </div>
  );
};

export default Articles;
